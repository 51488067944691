import React from 'react'
import { Link } from 'react-router-dom'
import LandingFooter from '../components/landing-components/landingFooter'
import LandingNav from '../components/landing-components/landingNav'

function FAQs() {
    return (
        <div className="bg-black bg-[image:radial-gradient(80%_50%_at_50%_-20%,hsl(206,81.9%,65.3%,0.5),rgba(255,255,255,0))]">
            <LandingNav />
            
            <section className="relative lg:min-h-[1000px]">
                <section className="py-12 sm:py-16 lg:py-20 xl:py-24">
                    <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl mt-20">
                        <div className="max-w-lg mx-auto text-center lg:max-w-2xl">
                            <h2 className="text-3xl font-normal text-white sm:mt-8 sm:text-4xl lg:text-5xl xl:text-6xl">
                                <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-600 to-white">
                                    GemSave Frequently Asked Questions
                                </span>
                            </h2>

                            <p className="mt-6 text-lg font-normal text-gray-400">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.</p>
                        </div>

                        <section className="py-10">
                                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                                    <div className="max-w-xl mx-auto mt-12 sm:mt-16">
                                        <div x-data="{ active: 1 }" className="space-y-4">
                                            <div
                                                className="px-4 py-5 border border-gray-900 rounded-lg">
                                                <h3>
                                                    <button
                                                        className="flex items-center justify-between w-full space-x-6 text-base font-bold text-left text-white">
                                                        <span className="flex-1">
                                                            What exactly the NFT is?
                                                        </span>
                                                        <span x-show="expanded" aria-hidden="true">
                                                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                stroke="currentColor" stroke-width="2">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
                                                            </svg>
                                                        </span>
                                                        <span x-show="!expanded" aria-hidden="true">
                                                            <svg className="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </h3>

                                                <div x-show="expanded" x-collapse>
                                                    <div className="pt-4 text-base font-medium text-white">
                                                        They are bought and sold online, frequently with cryptocurrency, and they are generally
                                                        encoded
                                                        with
                                                        the same underlying
                                                        software as many cryptos.
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className="px-4 py-5 border border-gray-900 rounded-lg">
                                                <h3>
                                                    <button
                                                        className="flex items-center justify-between w-full space-x-6 text-base font-bold text-left text-white">
                                                        <span className="flex-1">
                                                            How can I collect NFT?
                                                        </span>
                                                        <span x-show="expanded" aria-hidden="true">
                                                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                stroke="currentColor" stroke-width="2">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
                                                            </svg>
                                                        </span>
                                                        <span x-show="!expanded" aria-hidden="true">
                                                            <svg className="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </h3>

                                                <div x-show="expanded" x-collapse>
                                                    <div className="pt-4 text-base font-medium text-white">
                                                        They are bought and sold online, frequently with cryptocurrency, and they are generally
                                                        encoded
                                                        with
                                                        the same underlying
                                                        software as many cryptos.
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className="px-4 py-5 border border-gray-900 rounded-lg">
                                                <h3>
                                                    <button
                                                        className="flex items-center justify-between w-full space-x-6 text-base font-bold text-left text-white">
                                                        <span className="flex-1">
                                                            When will Craft NFT release?
                                                        </span>
                                                        <span x-show="expanded" aria-hidden="true">
                                                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                stroke="currentColor" stroke-width="2">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
                                                            </svg>
                                                        </span>
                                                        <span x-show="!expanded" aria-hidden="true">
                                                            <svg className="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </h3>

                                                <div x-show="expanded" x-collapse>
                                                    <div className="pt-4 text-base font-medium text-white">
                                                        They are bought and sold online, frequently with cryptocurrency, and they are generally
                                                        encoded
                                                        with
                                                        the same underlying
                                                        software as many cryptos.
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className="px-4 py-5 border border-gray-900 rounded-lg">
                                                <h3>
                                                    <button
                                                        className="flex items-center justify-between w-full space-x-6 text-base font-bold text-left text-white">
                                                        <span className="flex-1">
                                                            Will I own the full access?
                                                        </span>
                                                        <span x-show="expanded" aria-hidden="true">
                                                            <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                stroke="currentColor" stroke-width="2">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
                                                            </svg>
                                                        </span>
                                                        <span x-show="!expanded" aria-hidden="true">
                                                            <svg className="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </h3>

                                                <div>
                                                    <div className="pt-4 text-base font-medium text-white">
                                                        They are bought and sold online, frequently with cryptocurrency, and they are generally
                                                        encoded
                                                        with
                                                        the same underlying
                                                        software as many cryptos.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                            <div className="max-w-5xl mx-auto mt-8 overflow-hidden text-center bg-gray-900 sm:mt-12 rounded-xl border border-gray-600">
                                <div className="px-6 py-12 sm:p-12">
                                    <div className="max-w-sm mx-auto">
                                        <div className="relative z-0 flex items-center justify-center -space-x-2 overflow-hidden">
                                            <img className="relative z-10 inline-block rounded-full w-14 h-14 ring-4 ring-gray-100" src="https://landingfoliocom.imgix.net/store/collection/saasui/images/faq/1/avatar-male.png" alt="" />
                                            <img className="relative z-30 inline-block w-16 h-16 rounded-full ring-4 ring-gray-100" src="https://landingfoliocom.imgix.net/store/collection/saasui/images/faq/1/avatar-female-1.png" alt="" />
                                            <img className="relative z-10 inline-block rounded-full w-14 h-14 ring-4 ring-gray-100" src="https://landingfoliocom.imgix.net/store/collection/saasui/images/faq/1/avatar-female-2.png" alt="" />
                                        </div>

                                        <h3 className="mt-6 text-2xl font-semibold text-white">
                                            Still have questions?
                                        </h3>
                                        <p className="mt-2 text-base font-normal text-white">
                                            Can't find the answer you're looking for? Please email and talk to our friendly team members on
                                        </p>
                                        <p className="mt-4 text-4xl font-bold text-white">
                                           taltous@gemsave.app
                                        </p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </section>

            </section>

            <LandingFooter />
        </div>
    )
}

export default FAQs