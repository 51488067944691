import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from '../components/layouts/Navbar/Navbar'

import LandingFooter from '../components/landing-components/landingFooter'
import LandingNav from '../components/landing-components/landingNav'

// import logo image
import MainLogo from '../assets/images/gemSave-whiteLogo.png'

function Login() {

  // navigate
  const navigate = useNavigate();

  return (
    <div className='bg-black bg-[image:radial-gradient(80%_50%_at_50%_-20%,hsl(206,81.9%,65.3%,0.5),rgba(255,255,255,0))]'>
      <div>
        <header class="absolute inset-x-0 top-0 z-10">
          <div class="relative flex justify-center">
            <div class="mx-4 w-full max-w-7xl border-b border-white/10">
              <nav class="flex min-h-[5rem] items-center justify-between px-4 py-3" aria-label="Global">
                <div class="flex lg:flex-1">
                  <Link to='/'>
                    <img src={MainLogo} alt="" className='w-20' />
                  </Link>
                </div>

                <div
                  class="flex flex-1 items-center justify-end space-x-4 text-sm font-medium text-zinc-300 transition hover:text-zinc-100 sm:space-x-6">
                  <Link to='/auth/login'>
                    <a>
                      Home
                    </a>
                  </Link>

                  <button
                    class="group relative rounded-full p-px text-sm/6 text-zinc-400 duration-300 hover:text-zinc-100 hover:shadow-glow"
                    type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:R19la:"
                    data-state="closed"><span class="absolute inset-0 overflow-hidden rounded-full"><span
                      class="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span></span>

                    <div class="relative z-10 rounded-full bg-zinc-950 px-4 py-1.5 ring-1 ring-white/10">
                      Join the waitlist</div><span
                        class="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-cyan-400/0 via-cyan-400/90 to-cyan-400/0 transition-opacity duration-500 group-hover:opacity-40"></span>
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </header>
      </div>

      <section className="py-32">
        <div className="px-6 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-sm mx-auto my-40 py-14 text-white text-center">
            <h2>
              Ooopsssss
            </h2>
            <h1>
              Page Not Found
            </h1>
          </div>
        </div>
      </section>

      <LandingFooter />
    </div>
  )
}

export default Login