import React from 'react'
import { Text } from '@nextui-org/react'
import { Link } from 'react-router-dom'

function RecentTransactions() {

    // all tabs
    const tabItems = ["Statement (Transactions)", "Earning Statistics"]
    const [selectedItem, setSelectedItem] = React.useState(0)

    return (
        <div>
            {/* filter Tabs area */}
            {/* recent transactions tab */}
            <div>
                <ul role="tablist" className="hidden max-w-screen-xl px-2.5 items-center gap-x-3 overflow-x-auto text-sm rounded-md sm:flex border border-purple-100 shadow-sm hover:bg-gray-50">
                    {
                        tabItems.map((item, idx) => (
                            <li key={idx} className="py-3">
                                <button
                                    role="tab"
                                    aria-selected={selectedItem == idx ? true : false}
                                    aria-controls={`tabpanel-${idx + 1}`}
                                    className={`text-black tabsResultsT ext py-2.5 px-4 rounded-lg duration-150 hover:text-gray-500 hover:bg-gray-50 border active:bg-gray-100 font-medium ${selectedItem == idx ? "bg-gray-100 shadow-lg text-gray-500" : "text-gray-500"}`}
                                    onClick={() => setSelectedItem(idx)}
                                >
                                    {item}
                                </button>
                            </li>
                        ))
                    }

                    {/* dates filters */}
                    <div className='datesFilters ml-auto shadow-sm'>

                        <Link>
                            <div className="bg-gray-100 p-2 rounded-md shadow-md flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>

                                <span className='font-light'>
                                    <Text b>
                                        13 Nov 23 - 1 December 23
                                    </Text>
                                </span>
                            </div>
                        </Link>


                    </div>
                    <Link>

                        <div className="bg-gray-100 p-2 rounded-md shadow-md">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                            </svg>
                        </div>
                    </Link>
                </ul>

                <div className="relative text-gray-500 sm:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="pointer-events-none w-5 h-5 absolute right-2 inset-y-0 my-auto">
                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                    </svg>
                    <select value={tabItems[selectedItem]} className="p-3 w-full bg-transparent appearance-none outline-none border rounded-md shadow-sm focus:border-indigo-600"
                        onChange={(e) => setSelectedItem(tabItems.indexOf(e.target.value))}
                    >
                        {
                            tabItems.map((item, idx) => (
                                <option key={idx} idx={idx}>
                                    {item}
                                </option>
                            ))
                        }
                    </select>
                </div>

                {/* fetch transactions */}
                <div className='rounded-md sm:flex border border-purple-100 h- mt-2 hover:bg-gray-50'>

                    {/* fetch table */}
                    <div>
                        <div className="mt-1 ml-4 relative h-max overflow-auto">
                            <table className="w-full table-auto text-sm text-left">
                                <thead className="text-gray-500 font-light border-b">
                                    <tr>
                                        <th className="py-3 pr-40">Name of Transactions</th>
                                        <th className="py-3 pr-32">Date</th>
                                        <th className="py-3 pr-32">Status</th>
                                        <th className="py-3 pl-auto">Amount</th>
                                    </tr>
                                </thead>
                            </table>

                            <div className='mt-9'>
                                <Text sm className='text-center pb-14'>
                                    no transactions found
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default RecentTransactions