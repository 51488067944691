import React from 'react'
import { Link, useLocation } from 'react-router-dom'

// import logo image
import GemPayLogoMain from '../../../assets/images/gemPayLogo.png'

function Navbar(props) {
    const location = useLocation()
    const { window, children } = props;

    const setBorderAndColor = (path) => {
        if (location.pathname === path) {
            return "custom-active-link"
            // } else if (anotherPath === path) {
            //     return "border-r-4 border-pink-300 "
        }
    }

    const [profileMenu, setProfileMenu] = React.useState(false)
    return (
        <div>
            <header className="py-4 sm:py-5 shadow-sm border-b-1 bg-stone-100">
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl mt-1">
                    <nav className="flex items-center justify-between">
                        <div className="flex shrink-0">
                            <Link to="/account">
                                <a title="" className="flex">
                                    <img className="w-auto h-9" src={GemPayLogoMain} alt=""></img>
                                </a>
                            </Link>
                        </div>

                        <div className="flex lg:hidden">
                            <button type="button" className="text-gray-900">
                                <span x-show="!expanded" aria-hidden="true">
                                    <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 6h16M4 12h16M4 18h16" />
                                    </svg>
                                </span>

                                <span x-show="expanded" aria-hidden="true">
                                    <svg className="w-7 h-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="hidden lg:items-center lg:justify-start lg:ml-24 lg:mr-auto lg:space-x-10 lg:flex">
                            <Link to="/account">
                                <a title=""
                                    className={`navLinksText text-base font-small text-gray-600 transition-all duration-200 hover:text-gray-400 ${setBorderAndColor('/account')}`}>
                                    Home
                                </a>
                            </Link>

                            <Link to="/account/savingsandinterest">
                                <a title=""
                                    className={`navLinksText text-base font-small text-gray-600 transition-all duration-200 hover:text-gray-400 ${setBorderAndColor('/account/savingsandinterest')}`}>
                                    Savings Interest
                                </a>
                            </Link>

                            <Link to="/account/sendorreceive">
                                <a title=""
                                    className={`navLinksText text-base font-small text-gray-600 transition-all duration-200 hover:text-gray-400 ${setBorderAndColor('/account/sendorreceive')}`}>
                                    Transfer Money
                                </a>
                            </Link>

                            <Link to="/account/depositandwithdrawals">
                                <a title=""
                                    className={`navLinksText text-base font-small text-gray-600 transition-all duration-200 hover:text-gray-400 ${setBorderAndColor('/account/depositandwithdrawals')}`}>
                                    Deposit or Withdraw
                                </a>
                            </Link>

                            <Link to="/account/mycards">
                                <a title=""
                                    className={`navLinksText text-base font-small text-gray-600 transition-all duration-200 hover:text-gray-400 ${setBorderAndColor('/account/mycards')}`}>
                                    My Cards
                                </a>
                            </Link>

                            <Link to="/account/earnmoney">
                                <a title=""
                                    className={`navLinksText text-base font-small text-gray-600 transition-all duration-200 hover:text-gray-400 ${setBorderAndColor('/account/earnmoney')}`}>
                                    Earn Money
                                </a>
                            </Link>

                            <Link to="/account/discountdeals">
                                <a title=""
                                    className={`navLinksText text-base font-small text-gray-600 transition-all duration-200 hover:text-gray-400 ${setBorderAndColor('/account/discountdeals')}`}>
                                    Discount Deals
                                </a>
                            </Link>
                        </div>

                        <div className="hidden lg:flex lg:items-center lg:justify-end lg:space-x-4">

                            <div className="relative">
                                <Link to="/app/scanresults">
                                    <button type="button" className="p-1 text-gray-700 transition-all duration-200 bg-white rounded-full hover:text-gray-900 focus:outline-none hover:bg-gray-100 mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 
                                            1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
                                        </svg>
                                    </button>
                                </Link>

                                <Link to="/app/settings">
                                    <button type="button" className="p-1 text-gray-700 transition-all duration-200 bg-white rounded-full hover:text-gray-900 focus:outline-none hover:bg-gray-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 
                                            0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 
                                            1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                    </button>
                                </Link>

                            </div>

                            {
                                !profileMenu && (
                                    <button onClick={(() => { setProfileMenu(true) })} type="button" className="flex items-center max-w-xs rounded-full border border-pink-400 transition-all duration-200 focus:outline-none hover:bg-gray-100">
                                        <img className="object-cover bg-gray-300 rounded-full w-9 h-9 hover:shadow-xl hover:border-2" src="https://cdn.icon-icons.com/icons2/2292/PNG/512/doctor_black_male_people_avatar_icon_141440.png" alt="" />
                                    </button>
                                )
                            }

                            {
                                profileMenu && (
                                    <button onClick={(() => { setProfileMenu(false) })} type="button" className="flex items-center max-w-xs rounded-full border border-pink-400 transition-all duration-200 focus:outline-none hover:bg-gray-100">
                                        <img className="object-cover bg-gray-300 rounded-full w-9 h-9 hover:shadow-xl hover:border-2" src="https://cdn.icon-icons.com/icons2/2292/PNG/512/doctor_black_male_people_avatar_icon_141440.png" alt="" />
                                    </button>
                                )
                            }

                            {/* {
                                    profileMenu && (
                                        <div className="absolute right-0 z-10 profileMenu w-52 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                                                
                                            <div className="fixed inset-0 overlayArea" onClick={() => setProfileMenu(false)}></div>

                                            <div className="py-1 my-2" role="none">
                                                <Link to="/app/scanresults">
                                                    <p onClick={(() => { setProfileMenu(false) })} className="text-gray-700 block px-4 mb-3 text-sm pl-6 hover:bg-gray-50 hover:font-bold py-2" role="menuitem" tabIndex="-1" id="menu-item-0">
                                                        Dashboard
                                                    </p>
                                                </Link>

                                                <Link to="/app/settings">
                                                    <p onClick={(() => { setProfileMenu(false) })} className="text-gray-700 block px-4 py-3 text-sm pl-6 hover:bg-gray-50 hover:font-bold" role="menuitem" tabIndex="-1" id="menu-item-0">
                                                        Account settings
                                                    </p>
                                                </Link>

                                                <Link to="/app">
                                                    <p onClick={(() => { setProfileMenu(false) })} className="text-gray-700 block px-4 py-3 text-sm pl-6 hover:bg-gray-50 hover:font-bold" role="menuitem" tabIndex="-1" id="menu-item-0">
                                                        Support
                                                    </p>
                                                </Link>

                                                <Link to="/app/logout">
                                                    <p onClick={(() => { setProfileMenu(false) })} className="text-gray-700 block px-4 py-3 text-sm pl-6 hover:bg-gray-50 hover:font-bold" role="menuitem" tabIndex="-1" id="menu-item-0">
                                                        Log Out
                                                    </p>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                } */}

                        </div>
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Navbar