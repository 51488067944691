import React, { useState } from 'react'
import { Text } from '@nextui-org/react'
import { Link } from 'react-router-dom'

// layout
import MainLayout from '../../components/layouts/mainLayout'
import DashboardBtns from '../../components/Dashboard/DashboardBtns'

function Sendorreceive() {
    const [currency, setCurrency] = React.useState(0);

    const [countryCode, setCountryCode] = useState('UK');
    const [phoneNumber, setPhoneNumber] = useState('');

    return (
        <MainLayout>
            <div>
                <Text size={26} className='font-light'>
                    Transfer or Receive Money
                </Text>
                <p className='mt-0 pt-0 text-sm font-light'>
                    transfer money to a registered user
                </p>

                <div className="w-full mt-7 border bg-white shadow-md rounded-md">
                    <div className="px-8 py-5 sm:p-6">
                        <form className="px-4">
                            <div>
                                <label for="" className="text-sm font-light text-gray-900">Amount</label>
                                <div className="relative flex mt-2">
                                    <div className="inline-flex items-center px-3 text-gray-900 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm">USD $</div>
                                    <input type="number" name="" id="" placeholder="$ 0.00" value={currency} onChange={(e) => setCurrency(e.target.value)} className="flex-1 block w-full min-w-0 px-4 py-3 
                                     placeholder-gray-500 border-gray-300 border rounded-none rounded-r-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600" />
                                </div>
                            </div>

                            <div className="mt-6">
                                <div>
                                    <label for="" className="text-sm font-light text-gray-900">Transfer To ( phone number )</label>
                                    <div className="relative mt-2">
                                        <div className="absolute inset-y-0 left-0 flex items-center">
                                            <label htmlFor="" className="sr-only">Country code </label>
                                            <select className="h-full py-0 pl-3 text-gray-500 bg-gray-100 border border-gray-300 rounded-none rounded-l-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm" value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
                                                <option>SA 🇿🇦 (+27)</option>
                                                <option>ZW 🇿🇼 (+263)</option>
                                                <option>ZM 🇿🇲 (+260)</option>
                                                <option value="🇲🇿">MZ 🇲🇿 (+258)</option>
                                            </select>
                                        </div>

                                        <input type="tel" name="" id="" placeholder="User Phone number without country code . . ." value={phoneNumber} className="block border w-full py-3 pl-32 pr-4 
                                                 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600" onChange={(e) => setPhoneNumber(e.target.value)} />
                                    </div>
                                </div>
                            </div>


                            <div className="mt-6">
                                {/* confirm btn */}
                                <Link>
                                    <div className="bg-gray-300 p-2 rounded-md shadow-sm hover:shadow-md text-center border hover:border-purple-200">
                                        <div className="svgAndText">
                                            <div class="inline-flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                                <p class="font-light ml-2">
                                                    TRANSFER NOW
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </MainLayout>
    )
}

export default Sendorreceive