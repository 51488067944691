import React from 'react'
import { Text } from '@nextui-org/react'
import { Link } from 'react-router-dom'

// layout
import MainLayout from '../../components/layouts/mainLayout'

// import logos
import visaLogo from '../../assets/images/masterCardLogo.png'

function MyCards() {
    // all tabs
    const tabItems = ["All Cards", "Active", "In Active"]
    const [selectedItem, setSelectedItem] = React.useState(0)

    return (
        <MainLayout>
            <div>

                <Text size={26} className='font-light'>
                    Gemsave International Debit Cards
                </Text>

                {/* cards tabs */}
                <div className='mt-6'>
                    <ul role="tablist" className="hidden max-w-screen-xl px-2.5 items-center gap-x-3 overflow-x-auto text-sm rounded-md sm:flex border border-purple-100">
                        {
                            tabItems.map((item, idx) => (
                                <li key={idx} className="py-3">
                                    <button
                                        role="tab"
                                        aria-selected={selectedItem == idx ? true : false}
                                        aria-controls={`tabpanel-${idx + 1}`}
                                        className={`text-black tabsResultsT ext py-2.5 px-4 rounded-lg duration-150 hover:text-gray-500 hover:bg-gray-50 border active:bg-gray-100 font-medium ${selectedItem == idx ? "bg-gray-100 shadow-lg text-gray-500" : "text-gray-500"}`}
                                        onClick={() => setSelectedItem(idx)}
                                    >
                                        {item}
                                    </button>
                                </li>
                            ))
                        }

                        {/* dates filters */}
                        <div className='datesFilters ml-auto'>

                            <Link>
                                <div className="bg-gray-100 p-2 rounded-md shadow-md flex items-center border border-gray-300 hover:bg-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                                    </svg>

                                    <span className='font-light'>
                                        <Text b>
                                            Order Physical Card
                                        </Text>
                                    </span>
                                </div>
                            </Link>


                        </div>
                    </ul>

                    <div className="relative text-gray-500 sm:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="pointer-events-none w-5 h-5 absolute right-2 inset-y-0 my-auto">
                            <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                        </svg>
                        <select value={tabItems[selectedItem]} className="p-3 w-full bg-transparent appearance-none outline-none border rounded-md shadow-sm focus:border-indigo-600"
                            onChange={(e) => setSelectedItem(tabItems.indexOf(e.target.value))}
                        >
                            {
                                tabItems.map((item, idx) => (
                                    <option key={idx} idx={idx}>
                                        {item}
                                    </option>
                                ))
                            }
                        </select>
                    </div>

                    <div class="grid grid-cols-2 gap-4 mt-6">

                        {/* account main card */}
                        <Link className='border border-gray-100 rounded-md p-0.5'>
                            <div className="bg-black rounded-md shadow-md hover:shadow-l">
                                <div className="bg-gradient-to-r from-cyan-100 to-purple-400 rounded-t-md">
                                    <div class="grid grid-cols-3 gap-4 p-6">
                                        <div class="col-span-2 text-sm">
                                            <Text>
                                                GemSave Debit Card
                                            </Text>

                                            <Text className='text-xl pt-2'>
                                                4001 9192 5753 7193
                                            </Text>
                                        </div>

                                        <div >
                                            <img src={visaLogo} className='h-16 ml-auto pt-4' alt="" />
                                        </div>
                                    </div>

                                    <div className='pb-4 px-6'>
                                        <Text className='font-bold text-small'>
                                            default card
                                        </Text>
                                    </div>
                                </div>

                                <div className='px-6 py-2'>
                                    <Text className="textsOnCard text-sm" >
                                        Name
                                    </Text>
                                    <div class="grid grid-cols-3 gap-4">
                                        <div class="col-span-2 text-sm">
                                            <Text className='textsOnCard'>
                                                Collins Jimu
                                            </Text>
                                        </div>

                                        <div >
                                            <Text className='textsOnCard'>
                                                12/29
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>

                        <Link>
                            <div className='h-40 mt-6 border border-dotted border-gray-400 rounded-lg hover:shadow-md'>
                                <div className='mt-10'>
                                    <span className='flex items-center justify-center mt-2 font-light text-light'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-8 h-8">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </span>

                                    <span className='mt-1'>
                                        <Text className='text-center'>
                                            create new card
                                        </Text>
                                    </span>
                                </div>
                            </div>
                        </Link>

                        <Link>
                            <div className='h-40 mt-6 border border-dotted border-gray-400 rounded-lg hover:shadow-md'>
                                <div className='mt-10'>
                                    <span className='flex items-center justify-center mt-2 font-light text-light'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-8 h-8">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </span>

                                    <span className='mt-1'>
                                        <Text className='text-center'>
                                            create new card
                                        </Text>
                                    </span>
                                </div>
                            </div>
                        </Link>

                    </div>

                </div>
            </div>
        </MainLayout>
    )
}

export default MyCards