import React from 'react'
import { Text } from '@nextui-org/react'
import { Link } from 'react-router-dom'

// import logos
import visaLogo from '../../../assets/images/masterCardLogo.png'

function index() {
    return (
        <div>
            {/* Balance Component */}
            <Link>
                <div class="grid grid-cols-3 gap-4">
                    <div class="col-span-2">
                        <Text size={26} className='font-light'>
                            Your Main Balance
                        </Text>
                    </div>

                    <div>
                        <Text size={25}>
                            USD $0.00
                        </Text>
                    </div>
                </div>
            </Link>

            <div class="grid grid-cols-2 gap-4 mt-6">
                <div class="...">
                    {/* points balance */}
                    <Link to="/account/savingsandinterest">
                        <div className="rounded-md bg-gray-200 shadow-sm hover:shadow-md border border-purple-200 px-6 py-4">
                            <div className="svgAndText">
                                <div class="inline-flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <p class="font-light ml-2 text-sm">
                                        Interest Earned
                                    </p>
                                </div>
                            </div>

                            <div className='balanceComponent'>
                                <Text size={20}>
                                    USD 0.00
                                </Text>
                            </div>
                        </div>
                    </Link>
                </div>

                <div class="...">
                    {/* points balance */}
                    <Link to="/account/savingsandinterest">
                        <div className="rounded-md bg-gray-200 shadow-sm hover:shadow-md border border-purple-200 px-6 py-4">
                            <div className="svgAndText">
                                <div class="inline-flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <p class="font-light ml-2 text-sm">
                                        Points Earnings
                                    </p>
                                </div>
                            </div>

                            <div className='balanceComponent'>
                            <Text size={20}>
                                    COINS 0.00
                                </Text>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

            {/* account main card */}
            <Link to="/account/mycards">
                <div className="bg-black rounded-md shadow-md mt-6 p-0 hover:shadow-xl">
                    <div className="bg-gradient-to-r from-gray-100 to-purple-200 rounded-t-md">
                        <div class="grid grid-cols-3 gap-4 p-6">
                            <div class="col-span-2 text-sm">
                                <Text>
                                    GemSave Community Debit Card
                                </Text>

                                <Text className='text-xl pt-2'>
                                    4001 9192 5753 7193
                                </Text>
                            </div>

                            <div className='ml-auto'>
                                <img src={visaLogo} className='h-14' alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </Link>

            {/* action btn */}
            <Link className='/account/savingsandinterest'>
                <button className="flex items-center justify-center mt-6 w-full px-4 py-3 text-white font-medium bg-purple-600 hover:bg-purple-500 active:bg-purple-600 rounded-md duration-150">
                    <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </span>

                    <span className="font-bold text-md">Earn Interest</span>
                </button>
            </Link>

        </div>
    )
}

export default index