import React, { useState } from 'react'
import MainLogoWithText from "../../assets/mainLogoWithText.png"
import { Link, useNavigate } from 'react-router-dom'
import Navbar from '../../components/layouts/Navbar/Navbar'

import LandingFooter from '../../components/landing-components/landingFooter'
import LandingNav from '../../components/landing-components/landingNav'

// import logo image
import MainLogo from '../../assets/images/gemSave-whiteLogo.png'

function Login() {
  const [countryCode, setCountryCode] = useState('UK');
  const [phoneNumber, setPhoneNumber] = useState('');

  // navigate
  const navigate = useNavigate();

  return (
    <div className='bg-black bg-[image:radial-gradient(80%_50%_at_50%_-20%,hsl(206,81.9%,65.3%,0.5),rgba(255,255,255,0))]'>
        <div>
            <header class="absolute inset-x-0 top-0 z-10">
                <div class="relative flex justify-center">
                    <div class="mx-4 w-full max-w-7xl border-b border-white/10">
                        <nav class="flex min-h-[5rem] items-center justify-between px-4 py-3" aria-label="Global">
                            <div class="flex lg:flex-1">
                                <Link to='/'>
                                    <img src={MainLogo} alt="" className='w-20' />
                                </Link>
                            </div>

                            <div
                                class="flex flex-1 items-center justify-end space-x-4 text-sm font-medium text-zinc-300 transition hover:text-zinc-100 sm:space-x-6">
                                <Link to='/auth/login'>
                                    <a>
                                        Login
                                    </a>
                                </Link>

                                <button
                                    class="group relative rounded-full p-px text-sm/6 text-zinc-400 duration-300 hover:text-zinc-100 hover:shadow-glow"
                                    type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:R19la:"
                                    data-state="closed"><span class="absolute inset-0 overflow-hidden rounded-full"><span
                                        class="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span></span>

                                    <div class="relative z-10 rounded-full bg-zinc-950 px-4 py-1.5 ring-1 ring-white/10">
                                        Join the waitlist</div><span
                                            class="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-cyan-400/0 via-cyan-400/90 to-cyan-400/0 transition-opacity duration-500 group-hover:opacity-40"></span>
                                </button>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        </div>

      <section className="py-32">
        <div className="px-6 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-sm mx-auto">
            <div className="text-center">
              <h1 className="mt-12 text-3xl font-bold text-gray-300">Login to your account</h1>

              <p className="mt-2 text-gray-200">
                Don't have an account?
                <Link to="/auth/register">
                  <a href="javascript:void(0)" className="ml-2 font-bold">
                    Register Now
                  </a>
                </Link>
              </p>

            </div>

            <form className="mt-14">
              <div className="space-y-5">
                <div>
                  <label for="" className="text-md font-light text-gray-200">Phone Number </label>
                  <div className="mt-4">
                    <input type="email" name="" id="" placeholder="Enter phone number" value="" className="border border-gray-800 block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 bg-transparent" />
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label for="" className="text-md font-light text-gray-200"> Password </label>

                    <a href="#" title="" className="text-sm text-gray-200 font-bold"> Forgot Password? </a>
                  </div>

                  <div className="mt-4">
                    <input type="password" name="" id="" placeholder="Password (min. 8 character)" value="" className="border border-gray-800 block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 bg-transparent" />
                  </div>
                </div>

                <Link to="/account">
                  <div>
                    <button
                      type="submit"
                      className="mt-5 inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all  
                      duration-200 text-white bg-white/20 hover:bg-white/30 focus:bg-white/40 rounded-lg hover:bg-gray-500 border border-gray-500"
                    >
                      Proceed Login
                    </button>
                  </div>
                </Link>

              </div>
            </form>

            <div className="mt-5">
              <button
                type="button"
                className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
              >
                <img className="w-5 h-5 mr-2" src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/previews/sign-in/1/google-logo.svg" alt="" />
                Log in with Google
              </button>
            </div>

          </div>
        </div>
      </section>

      <LandingFooter />
    </div>
  )
}

export default Login