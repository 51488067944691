import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MainLogo from '../../assets/images/gemSave-whiteLogo.png'
import { Modal, Button, Text, Input, Row, Checkbox } from "@nextui-org/react";

function LandingNav() {
    const [showModal, setShowModal] = React.useState(false)

    function closeHandler(params) {
        setShowModal(false)
    }

    const [showMenu, setShowMenu] = React.useState(false)
    console.log(showMenu)

    // to do with submission of waitlist
    const [waitListed, setWaitListed] = React.useState(false)

    const [fullname, setFullname] = React.useState()
    const [phoneNumber, setPhoneNumber] = React.useState()
    const [emailAddress, setEmailAddress] = React.useState()

    return (
        <div>
            <header className="absolute inset-x-0 top-0 z-10 w-full border-b border-gray-700 mx-auto max-w-7xl">
                <div className="px-4 mx-auto sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16 lg:h-20">
                        <div class="flex lg:flex-1">
                            <Link to='/'>
                                <img src={MainLogo} alt="" className='w-20' />
                            </Link>
                        </div>

                        <div className="hidden lg:flex lg:items-center lg:justify-center lg:space-x-12 mx-auto mr-10">
                            <Link to="/">
                                <a title="" className="text-white transition-all duration-200 hover:text-opacity-80 font-light">
                                    Home
                                </a>
                            </Link>

                            <Link to="/features">
                                <a title="" className="text-white transition-all duration-200 hover:text-opacity-80 font-light">
                                    Features and Solution
                                </a>
                            </Link>

                            <Link to="/faqs">
                                <a title="" className="text-white transition-all duration-200 hover:text-opacity-80 font-light">
                                    FAQ's
                                </a>
                            </Link>
                        </div>

                        <div className="lg:flex lg:items-center lg:justify-end lg:space-x-6">
                            <button
                                onClick={(() => { setShowModal(true) })}
                                class="group border border-gray-700 relative rounded-full p-px text-sm/6 text-zinc-400 duration-300 hover:text-zinc-100 hover:shadow-glow"
                                type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:R19la:"
                                data-state="closed"><span class="absolute inset-0 overflow-hidden rounded-full"><span
                                    class="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100"></span></span>

                                <div class="relative z-10 rounded-full bg-zinc-950 px-4 py-1.5 ring-1 ring-white/10 text-gray-100 hover:text-white">
                                    Join the waitlist
                                </div>
                                <span class="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-cyan-400/0 via-cyan-400/90 to-cyan-400/0 transition-opacity duration-500 group-hover:opacity-40"></span>
                            </button>
                        </div>

                        <button
                            type="button" className="inline-flex p-2 text-white transition-all duration-200 rounded-md sm:ml-4 lg:hidden focus:bg-gray-800 hover:bg-gray-800 border border-gray-600 bg-gray-700">
                            <svg className="block w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
                            </svg>

                            <svg className="hidden w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                            </svg>
                        </button>
                    </div>
                </div>

            </header>

            <Modal
                aria-labelledby="modal-title"
                open={showModal}
                onClose={closeHandler}
                className='border border-gray-600 mx-2.5 waitlistModal'
            >
                <Modal.Body>
                    <div className="relative w-full max-w-md mx-auto overflow-hidden rounded-sm">
                        <div className="absolute top-0 right-0 pt-3 pr-3">
                            <button type="button"
                                onClick={closeHandler}
                                className="p-1 text-gray-400 transition-all duration-200 rounded-sm hover:bg-gray-300 
                                 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2">
                                <span className="sr-only">
                                    Close
                                </span>

                                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12">
                                    </path>
                                </svg>
                            </button>
                        </div>

                        <div className="p-3.5">
                            <div>

                                {
                                    waitListed &&
                                    <div>
                                        <div className='text-center'>
                                            <div class="flex justify-center items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 text-white text-center">
                                                    <path stroke-linecap="round" stroke-l inejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                                </svg>
                                            </div>


                                            <p className="mt-6 text-xl font-bold text-gray-200">
                                                Successfully Added to Wait list
                                            </p>
                                            <p className="mt-3 text-base font-medium text-gray-400">
                                                Hey there, you have been Successfully added to our waitlist. You will hear from us as soon as possible.
                                            </p>
                                        </div>
                                    </div>

                                    ||
                                    <div>
                                        {/* waitlist form */}
                                        <div className='text-center'>
                                            <p className="mt-6 text-xl font-bold text-gray-200">
                                                Join Our Wait list
                                            </p>
                                            <p className="mt-3 text-base font-medium text-gray-400">
                                                Join our growing waitlist of happy customers and we will reach out to you.
                                            </p>
                                        </div>
                                        <form className="mt-6">
                                            <div className="space-y-4">
                                                <div>
                                                    <label for="" className="text-base font-medium text-gray-400">Fullname:</label>
                                                    <div className="mt-2">
                                                        <input value={fullname} type="text" name="" id="" placeholder="Your Fullname" className="text-gray-300 border border-gray-800 block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 bg-transparent" />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label for="" className="text-base font-medium text-gray-400">Phone Number:</label>
                                                    <div className="mt-2">
                                                        <input type="text" name="" id="" placeholder="Enter your phone number" value={phoneNumber} className="text-gray-400 border-gray-800 border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 bg-transparent" />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label for="" className="text-base font-medium text-gray-400">Email Address:</label>
                                                    <div className="mt-2">
                                                        <input type="email" name="" id="" placeholder="e.g. email@email.com" value={emailAddress} className="text-gray-400 border border-gray-800 block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600 bg-transparent" />
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <button type="button"
                                                        onClick={() => { setWaitListed(true) }}
                                                        className="inline-flex items-center justify-center w-full px-6 py-4 text-xs font-bold tracking-widest text-gray-900 uppercase transition-all duration-200  
                                                border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 bg-gray-900 text-white">
                                                        JOIN NOW
                                                    </button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                }


                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {
                showMenu &&

                <nav x-show="expanded" x-collapse>
                    <div className="px-1 my-5 text-center bg-gray-900">
                        <div className="grid gap-y-7 pb-8">



                            <Link className="hover:text-gray-50" >
                                <a title="" className="text-base font-medium text-white transition-all duration-200 font-pj hover:text-opacity-50  focus:ring-gray-900 focus:ring-offset-2">
                                    jhhhhh
                                </a>
                            </Link>



                            <div className="gap-x-6 items-center justify-end space-y-6 md:flex md:space-y-0 md:mt-0">
                                <Link to="/auth/signin" className="block text-white">
                                    Sign in
                                </Link>
                            </div>

                        </div>
                    </div>
                </nav>
            }


        </div>
    )
}

export default LandingNav