import React from 'react'
import { Text } from '@nextui-org/react'

// layout
import MainLayout from '../../components/layouts/mainLayout'
import DashboardBtns from '../../components/Dashboard/DashboardBtns'

import RecentTransactions from '../../components/Dashboard/RecentTransactions/RecentTransactions'

function dashboard() {
    return (
        <MainLayout>
            <div>
                <Text size={26} className='font-light'>
                    Overview
                </Text>

                {/* dashboard btns */}
                <div>
                    <DashboardBtns />
                </div>

                <div className='my-9'>
                    <Text size={26} className='font-light'>
                        Recent Transactions and Earnings
                    </Text>
                </div>

                <div>
                    <RecentTransactions />
                </div>
            </div>
        </MainLayout>
    )
}

export default dashboard