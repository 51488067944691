import React from 'react'
import { Text } from '@nextui-org/react'
import { Link } from 'react-router-dom'
import { Modal } from "@nextui-org/react";

// layout
import MainLayout from '../../components/layouts/mainLayout'
import DashboardBtns from '../../components/Dashboard/DashboardBtns'

function Earnmoney() {
    const [clickedvalue, setClickedValue] = React.useState(0)
     
    return (
        <MainLayout>
            <div>

                <Text size={26} className='font-light'>
                    Earn Money
                </Text>

                <p className='mt-0 pt-0 text-sm font-light'>
                    available earning options
                </p>

                {/* earn options */}
                <div class="grid grid-cols-3 gap-4 mt-6">

                    <Link onClick={() => { setClickedValue(0) }}>
                        <div>
                            <div className={`h-18 border rounded-md ${clickedvalue === 0 ? 'bg-gray-200' : 'border border-red-100'} shadow-md hover:shadow-lg`}>
                                <div class="grid grid-cols-3 gap-0 p-4">
                                    <div class="mt-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                        </svg>
                                    </div>

                                    <div class="col-span-2 ...">
                                        <Text className='font-base text-sm'>
                                            Earned Coins
                                        </Text>
                                        <Text className='text-sm font-light'>
                                            earnings history
                                        </Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>

                    <Link onClick={() => { setClickedValue(1) }}>
                        <div>
                            <div className={`h-18 border rounded-md ${clickedvalue === 1 ? 'bg-gray-200' : 'border border-red-100'} shadow-md hover:shadow-lg`}>
                                <div class="grid grid-cols-3 gap-0 p-4">
                                    <div class="mt-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                        </svg>
                                    </div>

                                    <div class="col-span-2 mb-0.5">
                                        <Text className='font-base text-sm'>
                                            Change Coins
                                        </Text>
                                        <Text className='text-xs font-light'>
                                            change to balance
                                        </Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>

                    <Link onClick={() => { setClickedValue(2) }}>
                        <div>
                            <div className={`h-18 border rounded-md ${clickedvalue === 2 ? 'bg-gray-200' : 'border border-red-100'} shadow-md hover:shadow-lg`}>
                                <div class="grid grid-cols-3 gap-0 p-4">
                                    <div class="mt-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                        </svg>
                                    </div>

                                    <div class="col-span-2 mb-0.5">
                                        <Text className='font-base text-sm'>
                                            Refer and Earn
                                        </Text>
                                        <Text className='text-xs font-light'>
                                            refer a user
                                        </Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>

                </div>

                <div className="clickedFilters my-6">
                    {clickedvalue === 0 && (
                        // borrow money
                        <div>
                        </div>
                    )}

                    {clickedvalue === 1 && (
                        <div>
                        </div>
                    )}

                    {clickedvalue === 2 && (

                        // browse stockvels
                        <div>
                        </div>

                    )}

                    {clickedvalue === 3 && (

                        // browse stockvels
                        <div>
                            <div>
                                <Text className='font-light text-xl'>
                                    Interactive Rewards Based Financial Literacy Hub
                                </Text>

                                <p className="mt-0 font-light text-xs pt-0">
                                    learn and earn redemable coins whcih increases your priviledges and borrowing limits
                                </p>
                            </div>
                        </div>

                    )}

                    {clickedvalue === 4 && (
                        // join merchants
                        <h5 className="text-xl font-bold text-red-500">Clicked Value is 4</h5>
                    )}
                </div>

            </div>
        </MainLayout>
    )
}

export default Earnmoney