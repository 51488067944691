import React from 'react'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// import the pages
// landing pages
import Index from './pages'
import Features from './pages/features'
import Faqs from './pages/faqs'

// auth pages
import Login from './pages/auth/login'
import Register from './pages/auth/register'

// not found page
import NotFoundPage from './pages/notFound'

// account related routes
import AccountDashboard from './pages/account/dashboard'
import Savingsandinterest from './pages/account/savingsandinterest'
import Sendorreceive from './pages/account/sendorreceive'
import Depositandwithrawals from './pages/account/depositandwithrawals'
import MyCards from './pages/account/mycards'
import Earnmoney from './pages/account/earnmoney'
import DiscountDeals from './pages/account/discountdeals'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* auth routes */}
          <Route path="/" element={<Index />} />
          <Route path="/features" element={<Features />} />
          <Route path="/faqs" element={<Faqs />} />

          {/* auth pages */}
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/register" element={<Register />} />

          {/* account pages */}
          <Route path="/account" element={<AccountDashboard />} />
          <Route path="/account/savingsandinterest" element={<Savingsandinterest />} />
          <Route path="/account/sendorreceive" element={<Sendorreceive />} />

          <Route path="/account/depositandwithdrawals" element={<Depositandwithrawals />} />
          <Route path="/account/mycards" element={<MyCards />} />
          <Route path="/account/earnmoney" element={<Earnmoney />} />
          <Route path="/account/discountdeals" element={<DiscountDeals />} />

          {/* not found page */}
          <Route path='*' element={< NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
