import React from 'react'
import { Text } from '@nextui-org/react'
import { Link } from 'react-router-dom'
import { Modal } from "@nextui-org/react";

// layout
import MainLayout from '../../components/layouts/mainLayout'
import DashboardBtns from '../../components/Dashboard/DashboardBtns'

function SavingsAndInterest() {
    const [clickedvalue, setClickedValue] = React.useState(0)
    const [currency, setCurrency] = React.useState(0);

    const tabItems = ["1 Day", "7 Days", "30 Days", "365 Days"]

    const [showModal, setShowModal] = React.useState(false)

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    function closeHandler(params) {
        setShowModal(false)
    }

    const [selectedItem, setSelectedItem] = React.useState(0)

    return (
        <MainLayout>
            <div>

                <Text size={26} className='font-light'>
                    Earn Up To 15% Interest on Savings
                </Text>

                <p className='mt-0 pt-0 text-sm font-light'>
                    save money and earn
                </p>

            </div>
            <div>

                {/* the div with forms */}
                <div className="mt-7 bg-gray-150 pb-6 rounded-md shadow-md p-3.5 border">

                    <div class="grid grid-cols-1 m-4 gap-4">
                        <label for="" className="text-sm font-light text-gray-900 text-lg">Invest for How Long</label>
                        <div className=''>
                            <ul role="tablist" className="hidden max-w-screen-xl px-2.5 py-1.5 items-center gap-x-3 overflow-x-auto text-sm rounded-md sm:flex border border-purple-100">
                                {
                                    tabItems.map((item, idx) => (
                                        <li key={idx} className="py-3">
                                            <button
                                                role="tab"
                                                aria-selected={selectedItem == idx ? true : false}
                                                aria-controls={`tabpanel-${idx + 1}`}
                                                className={`text-black tabsResultsT ext py-2.5 px-4 rounded-lg duration-150 hover:text-gray-500 hover:bg-gray-50 border active:bg-gray-100 font-medium ${selectedItem == idx ? "bg-gray-100 shadow-lg text-gray-500" : "text-gray-500"}`}
                                                onClick={() => setSelectedItem(idx)}
                                            >
                                                {item}
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>

                        <div>
                            <label for="" className="text-sm font-light text-gray-900 text-lg">Amount to Invest</label>
                            <div className="relative flex mt-4">
                                <div className="inline-flex items-center px-3 text-gray-900 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm">USD $</div>
                                <input type="number" name="" id="" placeholder="$ 0.00" value={currency} onChange={(e) => setCurrency(e.target.value)} className="flex-1 block w-full min-w-0 px-4 py-3 
                                    placeholder-gray-500 border-gray-300 border rounded-none rounded-r-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600" />
                            </div>
                        </div>
                    </div>

                    {/* confirm btn */}
                    <Link>
                        <div className="bg-gray-300 p-2 rounded-md shadow-md hover:shadow-lg hover:shadow-lg hover:border-gray-300 text-center mx-40 border border-purple-200">
                            <Text>
                                CONFIRM
                            </Text>
                        </div>
                    </Link>

                </div>

                {/* savings in progress */}
                <div className='mt-10'>
                    <div className='mb-4'>
                        <Text className='font-light text-xl'>
                            Your Savings
                        </Text>
                    </div>

                    <div className="py-2 px-4 items-center gap-x-3 overflow-x-auto text-sm rounded-md sm:flex border border-purple-100 shadow-sm">
                        <Text size={18} className='font-light'>
                            Savings In Progress
                        </Text>

                        {/* dates filters */}
                        <div className='datesFilters ml-auto shadow-sm'>
                            <Link>
                                <div className="bg-gray-100 hover:bg-gray-200 p-2 rounded-md shadow-sm flex items-center border border-purple-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                    </svg>

                                    <span className='font-light py-1 px-2'>
                                        <Text b>
                                            ADD SAVINGS
                                        </Text>
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div>

                    {/* fetch transactions */}
                    <div className='rounded-md sm:flex border border-purple-100 h-40 mt-2 hover:bg-gray-50'>

                        {/* fetch table */}
                        <div>
                            <div className="mt-1 ml-4 relative h-max overflow-auto">
                                <table className="w-full table-auto text-sm text-left">
                                    <thead className="text-gray-500 font-light border-b">
                                        <tr>
                                            <th className="py-3 pr-12 text-sm">Amount</th>
                                            <th className="py-3 pr-24 text-sm">Date Started</th>
                                            <th className="py-3 pr-10 text-sm">Days Remaining</th>
                                            <th className="py-3 pr-20 text-sm">Expected Interest</th>
                                            <th className="py-3 pl-auto text-sm">Actions</th>
                                        </tr>
                                    </thead>
                                </table>

                                <div className='mt-9'>
                                    <Text sm className='text-center'>
                                        no savings found
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>




        </MainLayout >
    )
}

export default SavingsAndInterest