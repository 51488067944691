import React from 'react'
import { Text } from '@nextui-org/react'
import { Link, useLocation } from 'react-router-dom';

// import relevant components
import Navbar from './Navbar/Navbar'
import Sidebar from './SideBar'
import Footer from './Footer/Footer';

function MainLayout(props) {
    const location = useLocation()
    const { window, children } = props;

    return (
        <div className='bg-white'>
            <Navbar />

            <div class="grid grid-cols-3 gap-4 px-4 mx-auto sm:px-6 lg:px-10 max-w-7xl mt-7 mb-20">
                <div class="col-span-2 mr-10">
                    {children}
                </div>

                <div className='mt-14'>
                    <Sidebar />
                </div>
            </div>

            <Footer />

        </div>
    )
}

export default MainLayout