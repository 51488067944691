import React from 'react'
import { Text } from '@nextui-org/react'
import { Link } from 'react-router-dom'
import { Modal } from "@nextui-org/react";

// layout
import MainLayout from '../../components/layouts/mainLayout'

function DiscountDeals() {
    return (
        <MainLayout>
            <div>

                <Text size={26} className='font-light'>
                    Discount Deals
                </Text>

                <p className='mt-0 pt-0 text-sm font-light'>
                    available discount deals and offers
                </p>

            </div>
        </MainLayout>
    )
}

export default DiscountDeals