import React from 'react'
import { Link } from 'react-router-dom'
import LandingFooter from '../components/landing-components/landingFooter'
import LandingNav from '../components/landing-components/landingNav'

function features() {
    return (
        <div className="bg-black bg-[image:radial-gradient(80%_50%_at_50%_-20%,hsl(206,81.9%,65.3%,0.5),rgba(255,255,255,0))]">
            <LandingNav />
            
            <section className="relative lg:min-h-[1000px]">
                <section className="py-12 sm:py-16 lg:py-20 xl:py-24">
                    <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl mt-20">
                        <div className="max-w-lg mx-auto text-center lg:max-w-2xl">
                            <h2 className="text-3xl font-normal text-white sm:mt-8 sm:text-4xl lg:text-5xl xl:text-6xl">
                                <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-600 to-white">
                                    Stay informed & inspired wherever you are
                                </span>
                            </h2>

                            <p className="mt-6 text-lg font-normal text-gray-400">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.</p>
                        </div>

                        <div className="grid max-w-md grid-cols-1ACC gap-6 mx-auto mt-12 text-center lg:max-w-none lg:grid-cols-3 sm:mt-16 lg:mt-20">
                            <Link>
                                <div className="relative overflow-hidden bg-gray-900 rounded-lg shadow-lg border border-gray-700">
                                    <div className="px-6 pt-8 pb-56 sm:pb-80 lg:pb-52 xl:pb-64 sm:px-12 lg:px-8 xl:px-12 sm:pt-14">
                                        <h3 className="text-sm font-normal tracking-widest uppercase">
                                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> Unlimited resources </span>
                                        </h3>
                                        <p className="mt-3 text-2xl font-normal text-white xl:text-3xl">Design Things Fast</p>
                                        <p className="mt-4 text-base font-normal text-gray-400">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                                    </div>

                                    <div className="absolute inset-x-0 bottom-0 flex items-center justify-center">
                                        <img className="object-contain w-full" src="https://landingfoliocom.imgix.net/store/collection/dusk/images/features/1/feature-1.png" alt="" />
                                    </div>
                                </div>
                            </Link>

                            <Link>
                                <div className="relative overflow-hidden bg-gray-900 rounded-lg shadow-lg border border-gray-700">
                                    <div className="px-6 pt-8 pb-56 sm:pb-80 lg:pb-52 xl:pb-64 sm:px-12 lg:px-8 xl:px-12 sm:pt-14">
                                        <h3 className="text-sm font-normal tracking-widest uppercase">
                                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> Connect Easily </span>
                                        </h3>
                                        <p className="mt-3 text-2xl font-normal text-white xl:text-3xl">Grow Your Audience</p>
                                        <p className="mt-4 text-base font-normal text-gray-400">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                                    </div>

                                    <div className="absolute inset-x-0 bottom-0 flex items-center justify-center px-8 pb-6">
                                        <img className="object-contain w-full" src="https://landingfoliocom.imgix.net/store/collection/dusk/images/features/1/feature-2.png" alt="" />
                                    </div>
                                </div>
                            </Link>

                            <Link>
                                <div className="relative overflow-hidden bg-gray-900 rounded-lg shadow-lg border border-gray-700">
                                    <div className="px-6 pt-8 pb-56 sm:pb-80 lg:pb-52 xl:pb-64 sm:px-12 lg:px-8 xl:px-12 sm:pt-14">
                                        <h3 className="text-sm font-normal tracking-widest uppercase">
                                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> Use Anywhere </span>
                                        </h3>
                                        <p className="mt-3 text-2xl font-normal text-white xl:text-3xl">Support All Devices</p>
                                        <p className="mt-4 text-base font-normal text-gray-400">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                                    </div>

                                    <div className="absolute inset-x-0 bottom-0 flex items-center justify-center px-16 transform translate-y-24 sm:px-20 lg:translate-y-20 lg:px-12 xl:px-20">
                                        <img className="object-contain w-full" src="https://landingfoliocom.imgix.net/store/collection/dusk/images/features/1/feature-3.png" alt="" />
                                    </div>
                                </div>
                            </Link>

                            <Link>
                                <div className="relative overflow-hidden bg-gray-900 rounded-lg shadow-lg border border-gray-700">
                                    <div className="px-6 pt-8 sm:px-12 lg:px-8 xl:px-12 sm:pt-14 mb-12">
                                        <h3 className="text-sm font-normal tracking-widest uppercase">
                                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> Use Anywhere </span>
                                        </h3>
                                        <p className="mt-3 text-2xl font-normal text-white xl:text-3xl">Support All Devices</p>
                                        <p className="mt-4 text-base font-normal text-gray-400">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                                    </div>
                                </div>
                            </Link>

                            <Link>
                                <div className="relative overflow-hidden bg-gray-900 rounded-lg shadow-lg border border-gray-700">
                                    <div className="px-6 pt-8 sm:px-12 lg:px-8 xl:px-12 sm:pt-14 mb-12">
                                        <h3 className="text-sm font-normal tracking-widest uppercase">
                                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> Use Anywhere </span>
                                        </h3>
                                        <p className="mt-3 text-2xl font-normal text-white xl:text-3xl">Support All Devices</p>
                                        <p className="mt-4 text-base font-normal text-gray-400">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                                    </div>
                                </div>
                            </Link>

                            <Link>
                                <div className="relative overflow-hidden bg-gray-900 rounded-lg shadow-lg border border-gray-700">
                                    <div className="px-6 pt-8 sm:px-12 lg:px-8 xl:px-12 sm:pt-14 mb-12">
                                        <h3 className="text-sm font-normal tracking-widest uppercase">
                                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500"> Use Anywhere </span>
                                        </h3>
                                        <p className="mt-3 text-2xl font-normal text-white xl:text-3xl">Support All Devices</p>
                                        <p className="mt-4 text-base font-normal text-gray-400">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                                    </div>
                                </div>
                            </Link>

                        </div>
                    </div>
                </section>

            </section>

            <LandingFooter />
        </div>
    )
}

export default features