import React from 'react'
import { Text } from '@nextui-org/react'
import { Link } from 'react-router-dom'

// layout
import MainLayout from '../../components/layouts/mainLayout'
import DashboardBtns from '../../components/Dashboard/DashboardBtns'

function Depositandwithrawals() {
    const [currency, setCurrency] = React.useState(0);

    // all tabs
    const tabItems = ["Withdraw", "Deposit Money"]
    const [selectedItem, setSelectedItem] = React.useState(0)
    console.log(selectedItem)

    return (
        <MainLayout>
            <div>
                <Text size={26} className='font-light'>
                    Deposit and Withrawals
                </Text>

                {/* deposit btns */}
                <div className='mt-6'>
                    <ul role="tablist" className="hidden max-w-screen-xl px-2.5 py-1.5 items-center gap-x-3 overflow-x-auto text-sm rounded-md sm:flex border border-purple-100">
                        {
                            tabItems.map((item, idx) => (
                                <li key={idx} className="py-3">
                                    <button
                                        role="tab"
                                        aria-selected={selectedItem == idx ? true : false}
                                        aria-controls={`tabpanel-${idx + 1}`}
                                        className={`text-black tabsResultsT ext py-2.5 px-4 rounded-lg duration-150 hover:text-gray-500 hover:bg-gray-50 border active:bg-gray-100 font-medium ${selectedItem == idx ? "bg-gray-100 shadow-lg text-gray-500" : "text-gray-500"}`}
                                        onClick={() => setSelectedItem(idx)}
                                    >
                                        {item}
                                    </button>
                                </li>
                            ))
                        }

                        {/* dates filters */}
                        <div className='datesFilters ml-auto'>
                            <Link>
                                <div className="bg-gray-100 p-4 rounded-md shadow-md flex items-center border border-gray-300">
                                    <Text className='pr-4'>
                                        Account Balance
                                    </Text>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                    </svg>

                                    <span className='font-light'>
                                        <Text b>
                                            USD $0.00
                                        </Text>
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </ul>
                </div>

                <div className="mt-6">
                    {selectedItem === 0 && (
                        // withdraw funds money
                        <div>
                        <div className='mt-2'>
                            <div>
                                <Text className='font-light text-xl'>
                                    Withdraw Funds
                                </Text>

                                <p className="mt-0 font-light text-xs pt-0">
                                    withdraw funds from your account ...
                                </p>
                            </div>

                            <div className="w-full mt-7 border bg-white shadow-md rounded-md">
                                <div className="px-8 py-5 sm:p-6">
                                    <form className="mt-6 px-4">
                                        <div>
                                            <label htmlFor="" className="block text-sm text-gray-900">Withdraw How Much (Amount)</label>
                                            <div className="relative flex mt-2">
                                                <div className="inline-flex items-center px-3 text-gray-900 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm">USD $</div>
                                                <input type="number" name="" id="" placeholder="$ 0.00" value={currency} onChange={(e) => setCurrency(e.target.value)} className="flex-1 block w-full min-w-0 px-4 py-3 
                                        placeholder-gray-500 border-gray-300 border rounded-none rounded-r-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600" />
                                            </div>
                                        </div>

                                        <div className='my-4'>
                                            <label for="" className="text-sm font-light text-gray-900">Reason / Purpose for Withdrawing </label>
                                            <div className="mt-2">
                                                <input type="text" name="" id="" placeholder="John Doe" value="" className="block w-full px-4 py-3 placeholder-gray-500 border -gray-300 rounded-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600" />
                                            </div>
                                        </div>

                                        <div className="space-y-2">
                                            <div>
                                                <label for="" className="text-sm font-light text-gray-900">Withdraw Method ( withdraw via? )</label>
                                                <div className="mt-2">
                                                    <select className="block w-full py-3 pl-4 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm">
                                                        <option>choose method</option>
                                                        <option>bank</option>
                                                        <option>mobile wallet</option>
                                                        <option>merchant</option>
                                                        <option>community hub</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-end mt-5 space-x-4">
                                            <button
                                                type="reset"
                                                className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                            >
                                                Cancel
                                            </button>

                                            <button
                                                className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-purple-600 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600 hover:bg-purple-500"
                                            >
                                                Confirm Withdraw
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}

                    {selectedItem === 1 && (
                        // withdraw funds money
                        <div>
                            <div className='mt-6'>
                                <label for="" className="text-lg font-light text-gray-900">Deposit How Much</label>
                                <div className="relative flex mt-2">
                                    <div className="inline-flex items-center px-3 text-gray-900 border border-r-0 border-gray-300 rounded-l-md bg-gray-50 sm:text-sm">USD $</div>
                                    <input type="number" name="" id="" placeholder="$ 0.00" value={currency} onChange={(e) => setCurrency(e.target.value)} className="flex-1 block w-full min-w-0 px-4 py-3 
                                     placeholder-gray-500 border-gray-300 border rounded-none rounded-r-lg focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm caret-indigo-600" />
                                </div>
                            </div>


                            <div class="py-7">
                            <label for="" className="text-lg font-light text-gray-900">Payment Method</label>

                                <div class="mt-6 space-y-4">
                                    <div class="bg-white border-2 border-gray-900 rounded-md">
                                        <div class="px-4 py-5 sm:p-6">
                                            <div class="flex items-center">
                                                <div>
                                                    <span class="hidden">
                                                        <svg class="w-6 h-6 text-gray-300" viewBox="0 0 22 22" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="11" cy="11" r="10.25" stroke-width="1.5" />
                                                        </svg>
                                                    </span>

                                                    <span>
                                                        <svg class="w-6 h-6" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="11" cy="11" r="11" fill="#18181B" />
                                                            <path d="M6.91699 11.5833L9.25033 13.9166L15.0837 8.08331" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </span>
                                                </div>

                                                <div class="ml-4">
                                                    <p class="text-base font-bold text-gray-900">Credit Card</p>
                                                    <p class="mt-1 text-sm font-medium text-gray-500">Visa, Mastercard, American Amex</p>
                                                </div>
                                            </div>

                                            <div class="grid grid-cols-2 mt-5 sm:grid-cols-4 gap-x-6 gap-y-5">
                                                <div class="col-span-2 sm:col-span-4">
                                                    <label for="" class="text-sm font-medium text-gray-600"> Card number </label>
                                                    <div class="mt-2">
                                                        <input
                                                            type="text"
                                                            name=""
                                                            id=""
                                                            placeholder="XXXX XXXX XXXX XXXX"
                                                            class="block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900"
                                                        />
                                                    </div>
                                                </div>

                                                <div class="col-span-2">
                                                    <label for="" class="text-sm font-medium text-gray-600"> Name on the card </label>
                                                    <div class="mt-2">
                                                        <input
                                                            type="text"
                                                            name=""
                                                            id=""
                                                            placeholder="ex: John Doe"
                                                            class="block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900"
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label for="" class="text-sm font-medium text-gray-600"> Expiry date </label>
                                                    <div class="mt-2">
                                                        <input type="text" name="" id="" placeholder="MM/YYYY" class="block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900" />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label for="" class="text-sm font-medium text-gray-600"> CSV </label>
                                                    <div class="mt-2">
                                                        <input type="text" name="" id="" placeholder="XXX" class="block w-full px-4 py-3 text-sm font-normal text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-md caret-gray-900 focus:ring-gray-900 focus:border-gray-900" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="bg-white border-2 border-gray-200 rounded-md">
                                        <div class="px-4 py-5 sm:p-6">
                                            <div class="flex items-center">
                                                <div>
                                                    <span>
                                                        <svg class="w-6 h-6 text-gray-300" viewBox="0 0 22 22" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="11" cy="11" r="10.25" stroke-width="1.5" />
                                                        </svg>
                                                    </span>

                                                    <span class="hidden">
                                                        <svg class="w-6 h-6" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="11" cy="11" r="11" fill="#18181B" />
                                                            <path d="M6.91699 11.5833L9.25033 13.9166L15.0837 8.08331" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </span>
                                                </div>

                                                <div class="ml-4">
                                                    <p class="text-base font-bold text-gray-900">PayPal</p>
                                                    <p class="mt-1 text-sm font-medium text-gray-500">One click PayPal payment</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="bg-white border-2 border-gray-200 rounded-md">
                                        <div class="px-4 py-5 sm:p-6">
                                            <div class="flex items-center">
                                                <div>
                                                    <span>
                                                        <svg class="w-6 h-6 text-gray-300" viewBox="0 0 22 22" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="11" cy="11" r="10.25" stroke-width="1.5" />
                                                        </svg>
                                                    </span>

                                                    <span class="hidden">
                                                        <svg class="w-6 h-6" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="11" cy="11" r="11" fill="#18181B" />
                                                            <path d="M6.91699 11.5833L9.25033 13.9166L15.0837 8.08331" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </span>
                                                </div>

                                                <div class="ml-4">
                                                    <p class="text-base font-bold text-gray-900">Cryptocurrency</p>
                                                    <p class="mt-1 text-sm font-medium text-gray-500">Bitcoin, Ethereum</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </MainLayout>
    )
}

export default Depositandwithrawals