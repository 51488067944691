import React from 'react'

// import logo image
import GemPayLogoMain from '../../../assets/images/gemPayLogo.png'

function footer() {
  return (
    <div className='border-t bg-stone-100 py-2'>
      <div className='mt-9 px-4 mx-auto sm:px-6 lg:px-10 max-w-7xl'>
        <footer>
          <div>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-y-12 xl:gap-x-16 gap-x-8">
              <div className="col-span-2 md:col-span-4 lg:col-span-2">
                <a href="#" title="" className="flex">
                  <img className="w-auto h-9" src={GemPayLogoMain} alt="" />
                </a>

                <p className="mt-6 text-md font-normal text-black">
                  We are GemSave. Your Number one fintech app. We allow our Customers to securely save, learn, store and earn money from anywhere in Africa.
                </p>    

              </div>

              <div>
                <h6 className="text-xs font-bold tracking-widest text-gray-900 uppercase">Account</h6>

                <ul className="mt-8 space-y-4">
                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1">Savings and Interest </a>
                  </li>

                  <li>                        
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1">Deposit + Withdrawals </a>
                  </li>

                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1"> My Cards </a>
                  </li>

                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1"> Earn Money </a>
                  </li>
                </ul>
              </div>

              <div>
                <h6 className="text-xs font-bold tracking-widest text-gray-900 uppercase">Resources</h6>

                <ul className="mt-8 space-y-5">
                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1"> About </a>
                  </li>

                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1"> Features </a>
                  </li>

                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1"> Documentation </a>
                  </li>

                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1"> Developer Guides </a>
                  </li>
                </ul>

              </div>

              <div>
                <h6 className="text-xs font-bold tracking-widest text-gray-900 uppercase">More</h6>

                <ul className="mt-8 space-y-5">
                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1"> About Us </a>
                  </li>

                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1"> Contact Us </a>
                  </li>

                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1"> Contribute </a>
                  </li>

                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1">Support </a>
                  </li>
                </ul>
              </div>

              <div>
                <h6 className="text-xs font-bold tracking-widest text-gray-900 uppercase">More</h6>

                <ul className="mt-8 space-y-5">
                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1"> Guides </a>
                  </li>

                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1"> Terms & Conditions </a>
                  </li>

                  <li>
                    <a href="#" title="" className="flex text-base font-normal transition-all transform text-black hover:text-black duruation hover:translate-x-1"> Privacy Policy </a>
                  </li>
                </ul>
              </div>
            </div>

            <hr className="mt-4 border-gray-400 lg:mt-20 xl:mt-10 sm:mt-10" />

            <p className="mt-8 text-sm font-normal text-center text-black mb-10">© 2023 - 2024. All Rights Reserved by GemPaynSave Africa .Inc</p>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default footer