import React from 'react'

import { Text } from '@nextui-org/react'
import { Link } from 'react-router-dom'
import LandingFooter from '../components/landing-components/landingFooter'
import LandingNav from '../components/landing-components/landingNav'

function index() {
  return (
    <div className='bg-gradient-to-b from-[#101212] relative to-[#08201D]'>
      <LandingNav />

      <section class="relative isolate transform-gpu pt-8">
        <div
          class="absolute inset-0 -z-10 bg-[image:radial-gradient(80%_50%_at_50%_-20%,hsl(206,81.9%,65.3%,0.5),rgba(255,255,255,0))]">
        </div><svg
          class="absolute inset-0 -z-10 h-full w-full stroke-white/5 [mask-image:radial-gradient(75%_50%_at_top_center,white,transparent)]"
          aria-hidden="true">
          <defs>
            <pattern id="hero" width="80" height="80" x="50%" y="-1" patternUnits="userSpaceOnUse">
              <path d="M.5 200V.5H200" fill="none"></path>
            </pattern>
          </defs>
          <rect width="100%" height="100%" stroke-width="0" fill="url(#hero)"></rect>
        </svg>

        <section className="relative lg:min-h-[1000px] pt-24 pb-10 sm:pt- sm:pb-16 lg:pb-24">
          <div className="absolute inset-x-0 bottom-0 z-10 hidden lg:flex">
            <img className="hidden w-full lg:block" src="https://cdn.rareblocks.xyz/collection/celebration/images/hero/5/credit-cards.png" alt="" />
            <img className="block w-full lg:hidden" src="https://cdn.rareblocks.xyz/collection/celebration/images/hero/5/credit-cards-mobile.png" alt="" />
          </div>

          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative z-20 mt-10">

            <div className='max-w-xl mx-auto text-center'>
              <h1 className="text-4xl font-bold sm:text-6xl">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-600 to-white">Earn up to 15% Interest on all your Savings </span>
              </h1>
            </div>

            <div className="max-w-2xl mx-auto text-center pt-4">
              <p className="mt-8 text-base text-gray-300 sm:text-xl">No more hassle earning interest from savings and making payments. Try Gemsave and open an account, make your savings life simple and productive at ease from anywhere.</p>

              <div className='mt-8'>
                <h5 className='text-lg text-gray-300'>
                </h5>

                <div class="px-8 sm:items-center sm:justify-center sm:px-0 sm:space-x-5 sm:flex mt-6">
                  <div className="flex flex-col items-center justify-center space-y-5 sm:flex-row sm:space-y-0 sm:space-x-5 sm:justify-start">
                    <a href="#" title="" className="relative flex transition-all duration-200 transform hover:-translate-y-1" role="button">
                      <div className="absolute rounded-xl bg-gradient-to-r from-cyan-500 to-purple-500 -inset-px"></div>
                      <img className="relative w-auto rounded-xl h-14 md:h-12 lg:h-16" src="https://landingfoliocom.imgix.net/store/collection/dusk/images/application/2/app-store-button.svg" alt="" />
                    </a>

                    <a href="#" title="" className="relative flex transition-all duration-200 transform hover:-translate-y-1" role="button">
                      <div className="absolute rounded-xl bg-gradient-to-r from-cyan-500 to-purple-500 -inset-px"></div>
                      <img className="relative w-auto rounded-xl h-14 md:h-12 lg:h-16" src="https://landingfoliocom.imgix.net/store/collection/dusk/images/application/2/play-store-button.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
              <div>
                
              </div>

              <div className='max-w-xl mx-auto text-center'>
                <div className="grid grid-cols-1 px-20 mt-14 text-left gap-x-12 gap-y-8 sm:grid-cols-3 sm:px-0">
                  <div className="flex items-center">
                    <svg className="flex-shrink-0" width="31" height="25" viewBox="0 0 31 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M25.1667 14.187H20.3333C17.6637 14.187 15.5 16.3507 15.5 19.0203V19.8258C15.5 19.8258 18.0174 20.6314 22.75 20.6314C27.4826 20.6314 30 19.8258 30 19.8258V19.0203C30 16.3507 27.8363 14.187 25.1667 14.187Z"
                        stroke="#28CC9D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.7227 6.9369C18.7227 4.71276 20.5263 2.90912 22.7504 2.90912C24.9746 2.90912 26.7782 4.71276 26.7782 6.9369C26.7782 9.16104 24.9746 11.7702 22.7504 11.7702C20.5263 11.7702 18.7227 9.16104 18.7227 6.9369Z"
                        stroke="#28CC9D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.2231 15.8512H7.11157C3.73595 15.8512 1 18.5871 1 21.9628V22.9814C1 22.9814 4.18311 24 10.1674 24C16.1516 24 19.3347 22.9814 19.3347 22.9814V21.9628C19.3347 18.5871 16.5988 15.8512 13.2231 15.8512Z"
                        fill="#0B1715"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.07422 6.68386C5.07422 3.87152 7.35485 1.59088 10.1672 1.59088C12.9795 1.59088 15.2602 3.87152 15.2602 6.68386C15.2602 9.4962 12.9795 12.7954 10.1672 12.7954C7.35485 12.7954 5.07422 9.4962 5.07422 6.68386Z"
                        fill="#0B1715"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p className="ml-3 text-sm text-gray-300">Over 12,000 USD in savings</p>
                  </div>

                  <div className="flex items-center">
                    <svg className="flex-shrink-0" width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19.8335 21.9166H3.16683C2.6143 21.9166 2.08439 21.6972 1.69369 21.3065C1.30299 20.9158 1.0835 20.3858 1.0835 19.8333V3.16665C1.0835 2.61411 1.30299 2.08421 1.69369 1.69351C2.08439 1.30281 2.6143 1.08331 3.16683 1.08331H19.8335C20.386 1.08331 20.9159 1.30281 21.3066 1.69351C21.6973 2.08421 21.9168 2.61411 21.9168 3.16665V19.8333C21.9168 20.3858 21.6973 20.9158 21.3066 21.3065C20.9159 21.6972 20.386 21.9166 19.8335 21.9166Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path d="M7 12.6667L9.25 15L16 8" stroke="#28CC9D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <p className="ml-3 text-sm text-gray-300">No yearly charges, maximum limits</p>
                  </div>

                  <div className="flex items-center">
                    <svg className="flex-shrink-0" width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17 11H3C1.89543 11 1 11.8954 1 13V21C1 22.1046 1.89543 23 3 23H17C18.1046 23 19 22.1046 19 21V13C19 11.8954 18.1046 11 17 11Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M10 19C11.1046 19 12 18.1046 12 17C12 15.8954 11.1046 15 10 15C8.89543 15 8 15.8954 8 17C8 18.1046 8.89543 19 10 19Z" stroke="#28CC9D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M15 7V6C15.0131 4.68724 14.5042 3.42303 13.5853 2.48539C12.6664 1.54776 11.4128 1.01346 10.1 1H10C8.68724 0.986939 7.42303 1.4958 6.48539 2.41469C5.54776 3.33357 5.01346 4.58724 5 5.9V7"
                        stroke="#28CC9D"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p className="ml-3 text-sm text-gray-300">Secured & safe online payment</p>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </section>

      </section>

      <LandingFooter />
    </div>
  )
}

export default index